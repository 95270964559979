<template>
	<div id="Page-APD-List" class="main-wrap">
  <div  class="w850 ">
		
		<div class="list flex between wrap">
			<div v-for="item in list" v-bind:key="item.title" @click="doOpenContent(item)" class="item" :class="{unactive:item.status_apd != 1}">
				<div class="cover"><CoverWrap :src="item.cover+'?x-oss-process=image/resize,w_300'" width="188px" height="217px"/></div>
				<div class="mt-10"></div>
				<div class="stitle">APD No.{{item.id}}</div>
				<div class="title">{{L("第")}}{{L($root.n2c(item.id))}}{{L("届亚太设计年鉴")}}</div>
				<div class="mt-20"></div>
				<div class="mt-50"></div>
			</div>
			
			
			<div class="item empty" v-for="(item,index) in 10" :key="index"></div>
		</div>
  </div>
	</div>
</template>

<script>
	
import CoverWrap from '/src/components/CoverWrap.vue';
export default {
  name: 'Page-APD-List',
  components: {
    CoverWrap
  },
  data(){
    return {
			list:[],
			loading:false
    };
  },
  async mounted () {
		var list = this.$root.awardStageList || [];
		list = list.filter(item=>item.status_apd==1||item.status_apd==2);
		this.$set(this,'list',list);
		
  },
  methods: {
		doOpenContent(item){
			if(item.status_apd != 1){
				return ;
			}
			this.$root.toPage('/APD/Content/'+item.id)
			// window.open("/#/APD/Content/"+item.id);
		}
  }
}
</script>

<style scoped lang="less">
.list{
	.item{
		cursor: pointer;
		width:188px;
		text-align: center;
		.stitle,.title{font-size:18px;line-height: 1.4;word-break:break-all; word-wrap: break-word;cursor: pointer;}
		.date{color:#A0A0A0;font-size:22px;}
		.line{height:1px;background-color: #A0A0A0;}
		&.empty {
			height: 0px;
			width:188px;
			border:1px solid transparent;
		}
	}
}
</style>